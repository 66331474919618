import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Home2 from '../views/Home2.vue'
import Home3 from '../views/Home3.vue'
import Home4 from '../views/Home4.vue'
import Blog from '../views/Blog.vue'
import BlogDark from '../views/BlogDark.vue'
import BlogDetails from '../views/SingleBlog.vue'
import BlogDetailsDark from '../views/SingleBlogDark.vue'

const routes = [
  {
    path: '/Home1',
    name: 'Home1',
    component: Home
  },
  {
    path: '/',
    name: 'Home2',
    component: Home2
  },
  {
    path: '/home3',
    name: 'Home3',
    component: Home3
  },
  {
    path: '/home4',
    name: 'Home4',
    component: Home4
  },

  {
    path: '/blogs',
    name: 'Blogs',
    component: Blog
  },

  {
    path: '/blogs-dark',
    name: 'BlogsDark',
    component: BlogDark
  },
  
  {
    path: '/blog-details/:id',
    name: 'BlogDetails',
    component: BlogDetails
  },

    
  {
    path: '/blog-details-dark/:id',
    name: 'BlogDetailsDark',
    component: BlogDetailsDark
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
